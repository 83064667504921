.skillset-container {
  font-family: light;
  font-size: 19px;
  ;
}

.spacer {
  height: 30px;
}


.it-partner-section>p {
  font-size: 19px;
}
.it-partner-section>h1 {
  font-size: 30px;
}

@media screen and (max-width: 1200px) {

  .it-partner-section>p {
    font-size: 17px;
  }


  .it-partner-section>h1 {
    font-size: 21px;
  }
}

.skillset-container >p{
  font-size: 18px;
}