.service-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Default 3 columns */
    gap: 60px;
    /* Space between items */
    row-gap: 90px;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 60px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-family: light;
}

/* Make each card a column flex container */
.service-item {
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    opacity: 0;
    display: flex;
    flex-direction: column;
    /* Optional: give each card a min-height if you want uniform sizing
       min-height: 450px;
    */
}

.service-item.visible {
    opacity: 1;
    /* Fully visible */
    transform: translateY(0);
    /* Back to original position */
}

/* Ensure the title and text remain at the top */
.service-item-title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 23px;
    ;
}

/* Basic spacing for paragraphs */
.service-item-description {
    font-size: 17px;
    ;
}

/* Button group at the bottom */
.button-group {
    display: flex;
    gap: 10px;
    /* Space between the buttons */
    margin-top: auto;
    /* Pushes the entire group to the bottom of the card */
}

/* Button styles */
.anfrage-btn,
.learn-more-btn {
    padding-top: 2px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border: none;

    width: 100px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.2s, background-color 0.3s;
}

.anfrage-btn {
    background-color: #000000;
    transition: background-color 0.15s ease, transform 0.15s ease, border-radius 0.15s ease;

    /* Green button for "Anfrage" */
}


.not-on-grid {
    margin-bottom: 50px;
    border-radius: 10px;
    min-height: 35px;
    width: 25%;
}

.anfrage-btn:hover {
    background-color: #d0ffb9c6;
    transform: scale(1.05);
    color: black;
    border-radius: 20px; /* Increased border radius on hover */
}

.anfrage-btn:active {
    transform: scale(0.95);
    /* Slight shrink on click */
}

.learn-more-btn {
    background-color: #000000;
    /* Blue button for "Mehr Erfahren" */
}

.learn-more-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
    /* Darker blue on hover */
}

.learn-more-btn:active {
    transform: scale(0.95);
    /* Slight shrink on click */
}



.filter-container {
    margin-top: 20px;
    margin-left: 15%;
    font-family: light;
    font-size: 20px;

}

.filter-container select {
    margin-left: 8px;
    background-color: rgba(255, 255, 255, 0.456);
    padding: 3px;
    border-radius: 4px;;
}

.filter{
    width: 270px;
}


@media screen and (max-width: 1200px) {
    .service-grid {
        row-gap: 30px;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 40px;
        padding-bottom: 15px;
        grid-template-columns: repeat(1, 1fr);

    }

    .service-item {
        margin-bottom: 50px;
        
    }

    .anfrage-btn .learn-more-btn  {
        margin-bottom: 50px;
        border-radius: 10px;
        min-height: 35px;
        width: 50%;
    }


    .filter-container{
        margin-left: 4%;

    }

    .service-item-description {
        font-size: 16px;
        ;
    }

}